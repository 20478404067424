<template>
  <div>
    <step-progress :line-thickness="2" :active-thickness="2" :passive-thickness="2" active-color="#30CB8F" :steps="applySteps" :current-step="currentStep" icon-class="fa fa-check" style="max-width:780px"></step-progress>
  </div>
</template>

<script>
import StepProgress from 'vue-step-progress'
import 'vue-step-progress/dist/main.css'

export default {
  name: 'StepFormRegister',
  props: {
    currentStep: {
      type: Number,
      default: 0
    },
    titleListSteps: {
      type: Array,
      default: function () {
        return [this.$t('Start'), this.$t('Finished')]
      }
    }
  },
  components: {
    'step-progress': StepProgress
  },
  data () {
    return {
      applySteps: this.titleListSteps
    }
  }
}
</script>
<style >
  .img-wrapper-apply .overlay-text {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    height: 100%;
    width: 100%;
    font-size: 1.2rem;
  }
  .img-wrapper-apply .overlay-text:hover, .img-wrapper-apply .overlay-text.active {
    background: #21315983;
    color:white !important;
    cursor: pointer;
  }
  .step-progress__step--valid, .step-progress__step-label {
    font-size: 1rem !important;
  }
  .step-progress__step--valid .step-progress__step-label{
    color:#30CB8F !important;
  }
  .step-progress__step-label{
    color:#3B6098;
    font-weight: 500;
  }

  .step-progress__step--valid .step-progress__step-label{
    font-weight: bold;
  }

  .step-progress__step--active .step-progress__step-label{
      color:#3B6098;
      font-weight: bold;
  }
  .step-progress__step span {
    font-size: 1.2rem;
    color:#3B6098 !important;
    font-weight: 600;
  }
  .step-progress__wrapper-before {
    background: #F5F7FA !important;
  }
  .step-progress__step:after {
    border-color: #F5F7FA !important;
    background: #F5F7FA;
  }
  .step-progress__step--valid span {
    opacity: 1 !important;
    font-size: 0.6rem;
    margin-bottom: 2px;
    color:#fff !important;
  }
  .step-progress__step--valid .step-progress__step-label {
    margin-top: 5px;
  }
  .step-progress__step--valid:after {
    border-color: #30CB8F !important;
    background: #30CB8F;
  }
  .step-progress__step--active:after {
    background: #fff !important;
    /*border-color: #30CB8F !important;*/
    border-color: #3a6198 !important;
  }

  .step-progress__step span{
    display:inline;
  }
  .step-progress__step .step-progress__step-icon{
    display:none;
  }
  .step-progress__step.step-progress__step--valid span{
    display:none;
  }
  .step-progress__step.step-progress__step--valid .step-progress__step-icon{
    display:inline;
  }
  .step-progress__step.step-progress__step--active span{
    display:inline;
  }
  .step-progress__step.step-progress__step--active .step-progress__step-icon{
    display:none;
  }

  .step-progress__step-icon.fa.fa-check {
     background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='svg-inline--fa fa-check fa-w-16'%3E%3Cpath fill='%23fff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' class=''%3E%3C/path%3E%3C/svg%3E");
     background-repeat: no-repeat;
     width: 22px;
     height: 22px;
     content:" ";
  }
  .step-progress__step--valid .step-progress__step-label {
      margin-top: 14px;
  }

  @media screen and (max-width: 576px) {
    .step-progress__step-icon.fa.fa-check {
        width: 20px;
        height: 20px;
    }
    .step-progress__step-label {
      font-size: 0.7rem !important;
      width: 70px;
      overflow: hidden;
      text-align: center;
    }
  }
</style>
