<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:500px;background-color:#fff">
      <b-row class="mb-5">
        <b-col cols="12">
          <StepFormRegister :currentStep="this.currentStep" :titleListSteps="this.titleListSteps"/>
        </b-col>
        <b-col xl="12" lg="12" sm="12" md="12">
          <h3 class="content-header-title text-primary-dark">{{ $t('Payment Detail') }}</h3>
          <div class="mt-4">
            <b-row class="p-2 bg-gray">
              <b-col xl="6" lg="6" sm="12" md="12" class="">
                <div class="flex-justify-content-space-between px-2"><label class="text-primary-dark font-weight-600 pr-2">{{ $t('Invoice NO') }} :</label><span>{{ invoice.ref_invoice }}</span></div>
                <div class="flex-justify-content-space-between px-2"><label class="text-primary-dark font-weight-600 pr-2">{{ $t('Invoice Created') }} :</label><span>{{ invoice.created_at }}</span></div>
              </b-col>
              <b-col xl="6" lg="6" sm="12" md="12" class="">
                <div class="flex-justify-content-space-between px-2"><label class="text-primary-dark font-weight-600 pr-2">{{ $t('Type') }} :</label><span>One-time-fee</span></div>
                <div class="flex-justify-content-space-between px-2"><label class="text-primary-dark font-weight-600 pr-2">{{ $t('Payment Status') }} :</label><span>{{ invoice.status_name }}</span></div>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <div class="col-12 col-lg-8 mt-4">
              <b-row class="p-4 p-md-2">
              <b-button block v-b-toggle.collapse-bill-to variant="outline-success border-dashed" id="collapse-bill-to-toggle">{{ $t('Bill to') }}
                <span class="chevron-down"><b-icon icon="chevron-down" class=""></b-icon></span>
                <span class="chevron-up d-none"><b-icon icon="chevron-up" class=""></b-icon></span>
              </b-button>
                <b-collapse id="collapse-bill-to" class="mt-2">
                <!-- Bill to -->
                  <div class="col-12 pt-2">
                    <label class="text-primary-dark font-weight-600">{{ $t('Bill to')}}</label>
                    <b-card title="" sub-title="" class="w-100">
                      <b-card-text class="font-weight-bold text-capitalize">{{ invoice.first_name }} {{ invoice.last_name }}</b-card-text>
                      <b-card-text class=""><label class="font-weight-bold pr-2">{{ $t('Address') }} :</label><span>{{ invoice.company_name }} {{ invoice.address }} {{ invoice.region_name }} {{ invoice.country_name }} {{ invoice.post_code }}</span></b-card-text>
                      <b-card-text class=""><label class="font-weight-bold pr-2">{{ $t('Phone number') }} :</label><span>{{ invoice.phone_number }}</span></b-card-text>
                      <b-card-text class=""><label class="font-weight-bold pr-2">{{ $t('Email') }} :</label><span>{{ invoice.email }}</span></b-card-text>
                    </b-card>
                  </div>
                <!-- End bill to -->
                </b-collapse>
                <b-button block v-b-toggle.collapse-currency id="collapse-currency-toggle" class="mt-2" variant="outline-success border-dashed">{{ $t('Currency') }}
                  <span class="chevron-down"><b-icon icon="chevron-down" class=""></b-icon></span>
                  <span class="chevron-up d-none"><b-icon icon="chevron-up" class=""></b-icon></span>
                </b-button>
                <b-collapse id="collapse-currency" class="mt-2">
                  <!-- Currency -->
                  <div class="col-12 mt-4">
                    <label class="text-primary-dark font-weight-600 pr-2">{{ $t('Select Payment Currency') }} :</label><span class="text-success pr-2">{{ uppercase(invoice.paid_currency) }}</span><b-icon icon="check" class="text-success"></b-icon>
                  </div>
                  <!-- End Currency -->
                </b-collapse>
                <b-button block v-b-toggle.collapse-orders class="mt-2 border-dashed" variant="outline-success" id="collapse-orders-toggle">{{ $t('Orders') }}
                  <span class="chevron-down"><b-icon icon="chevron-down" class=""></b-icon></span>
                  <span class="chevron-up d-none"><b-icon icon="chevron-up" class=""></b-icon></span>
                </b-button>
                <b-collapse id="collapse-orders" class="mt-2" style="width: 100%">
                  <div class="col-12 mt-4">
                    <label class="text-primary-dark font-weight-600 pr-2">{{ $t('Orders') }}</label>
                    <div class="table-responsive" style="min-height: 60px;">
                      <b-table striped hover :items="orders" :fields="orderFields">
                        <template #cell(price)="row">
                          <span>
                            {{ getAmountByCurrency(row.item, 'price', invoice.paid_currency) | numeral('0,0.00') }}
                          </span>
                        </template>
                        <template #cell(total)="row">
                          <span>
                            {{ getAmountByCurrency(row.item, 'total', invoice.paid_currency) | numeral('0,0.00') }} <span class="text-uppercase">{{ invoice.paid_currency }}</span>
                          </span>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-collapse>
              </b-row>
              <b-row class="pt-4">
                <div class="payment-methode col-12">
                  <label class="col-12 text-primary-dark font-weight-600">{{ $t('Payment Methode')}}</label>
                  <b-card title="" sub-title="" class="bg-gray">
                    <!-- <b-col>
                      <b-button id="paypal-button" block class="bg-white border-0" size="sm"></b-button>
                    </b-col> -->
                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                      <div class="d-none payment-item flex-justify-content-space-around py-3">
                        <div class="payment-check">
                           <b-form-radio v-model="paymentSelected" :aria-describedby="ariaDescribedby" name="some-radios" value="card"></b-form-radio>
                        </div>
                        <div class="payment-title">
                          <h6 class="">{{ $t('Credit/Debit card') }}</h6>
                        </div>
                        <div class="payment-icon">
                          <span class="icon-item d-inline-block pr-1"><b-img :src="PUBLIC_PATH+'img/payment/visa.png'"></b-img></span>
                          <span class="icon-item d-inline-block pr-1"><b-img :src="PUBLIC_PATH+'img/payment/mastercard.jpg'"></b-img></span>
                          <span class="icon-item d-inline-block pr-2"><b-img :src="PUBLIC_PATH+'img/payment/jcb.png'"></b-img></span>
                        </div>
                      </div>
                      <div class="d-none payment-item flex-justify-content-space-around py-3">
                        <div class="payment-check">
                          <b-form-radio v-model="paymentSelected" :aria-describedby="ariaDescribedby" name="some-radios" value="internet-banking"></b-form-radio>
                        </div>
                        <div class="payment-title">
                          <h6 class="">Internet Banking</h6>
                        </div>
                        <div class="payment-icon">
                        </div>
                      </div>
                      <div class="payment-item flex-justify-content-space-around py-3">
                        <div class="payment-check">
                           <b-form-radio v-model="paymentSelected" :aria-describedby="ariaDescribedby" name="some-radios" value="paypal"></b-form-radio>
                        </div>
                        <div class="payment-title">
                          <h6 class="">Paypal</h6>
                        </div>
                        <div class="payment-icon">

                        </div>
                      </div>
                      <div class="payment-item flex-justify-content-space-around py-3">
                        <div class="payment-check">
                           <b-form-radio v-model="paymentSelected" :aria-describedby="ariaDescribedby" name="some-radios" value="other"></b-form-radio>
                        </div>
                        <div class="payment-title">
                          <h6 class="">{{ $t('Transfer') }}</h6>
                        </div>
                        <div class="payment-icon">

                        </div>
                      </div>
                    </b-form-group>
                    <div v-if="paymentSelected == 'other'">
                      <h5 class="font-weight-bold">{{ $t('Bank Account') }}</h5>
                      <div class="bg-white mt-2 p-2 rounded border-1" style="border:1px solid #e0dddd" v-for="(bank, index) in banks" :key="index">
                        <span class="d-block" v-if="bank.name"> <label class="font-weight-bold">{{ $t('Bank')}}</label>: {{ bank.name }}</span>
                        <span class="d-block" v-if="bank.account_name"> <label class="font-weight-bold">{{ $t('Account name') }}</label>: {{ bank.account_name }}</span>
                        <span class="d-block" v-if="bank.account_number"> <label class="font-weight-bold">{{ $t('Account number') }}</label>: {{ bank.account_number }}</span>
                        <span class="d-block" v-if="bank.branch"> <label class="font-weight-bold">{{ $t('Branch') }}</label>: {{ bank.branch }}</span>
                        <span class="d-block" v-if="bank.branch_number"> <label class="font-weight-bold">{{ 'Branch number' }}</label>: {{ bank.branch_number }}</span>
                        <span class="d-block" v-if="bank.swiftCode"> <label class="font-weight-bold">Swift Code</label>: {{ bank.swiftCode }}</span>
                        <span class="d-block" v-if="bank.iFSCCode"> <label class="font-weight-bold">IFSC Code</label>: {{ bank.iFSCCode }}</span>
                        <span class="d-block" v-if="bank.remark"> <label class="font-weight-bold">{{ $t('Remark') }}*</label>: {{ bank.remark }}</span>
                      </div>
                      <div class="bg-white mt-2 pt-2 pb-2 rounded">
                        <h5 class="mb-2 ml-3 font-weight-bold">{{ $t('Upload document transfer') }}</h5>
                        <ValidationObserver ref="formTransfer" v-slot="{ handleSubmit }">
                          <b-form @submit.stop.prevent="handleSubmit(onSubmitTransfer)">
                            <b-img v-bind="mainPropImage" v-if="imageTransfer"  :src="imageTransfer" fluid rounded alt="Image" style="max-width:460px"></b-img>
                            <validation-provider
                              :name="$t('Image')"
                              rules="required"
                              autocomplete="off"
                              v-slot="{errors}"
                            >
                            <b-form-group class="d-inline col-12 font-weight-bold text-primary-dark" for="document-transfer" :label="$t('Image')+'*'">
                              <b-form-file
                                v-model="formTransfer.document_transfer"
                                type="file"
                                id="document-transfer"
                                :placeholder="$t('Choose a file or drop it here')"
                                :drop-placeholder="$t('Drop file here')"
                                accept="image/*"
                              ></b-form-file>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                            </validation-provider>
                             <validation-provider
                              :name="$t('Date')"
                              autocomplete="off"
                              rules="required"
                              v-slot="{errors}"
                              >
                              <b-form-group class="d-inline col-12 font-weight-bold text-primary-dark" for="input-bank" :label="$t('Bank')+'*'">
                                <b-form-select
                                  v-model="formTransfer.bank"
                                  :options="bankOptions"
                                  rules="required"
                                  value-field="item"
                                  text-field="name"
                                  >
                                </b-form-select>
                              <small class="text-danger">{{ errors[0] }}</small>
                              </b-form-group>
                            </validation-provider>
                            <validation-provider
                              :name="$t('Date')"
                              autocomplete="off"
                              rules="required"
                              v-slot="{errors}"
                              >
                              <b-form-group class="d-inline col-12 font-weight-bold text-primary-dark" for="input-date" :label="$t('Date')+'*'">
                              <b-form-datepicker
                                id="input-date"
                                placeholder="dd/mm/yy"
                                v-model="formTransfer.transfer_date"
                                :locale="currentLanguage"
                                hideHeader
                                class="mb-2"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                @context="onContextDate"
                              >
                              </b-form-datepicker>
                              <small class="text-danger">{{ errors[0] }}</small>
                              </b-form-group>
                            </validation-provider>
                            <validation-provider
                              :name="$t('Time')"
                              autocomplete="off"
                              rules="required"
                              v-slot="{errors}"
                              >
                              <b-form-group class="d-inline col-12 font-weight-bold text-primary-dark" for="time" :label="$t('Time')+'*'">
                                <timepicker
                                  id="time"
                                  type="minute"
                                  autocomplete="off"
                                  v-model="formTransfer.transfer_time"
                                  :timeStr="timeStr"
                                  :locale="currentLanguage"
                                  :placeholder="$t('No time selected')"
                                  :btnStr="$t('Select')"
                                  style="height:36px"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </b-form-group>
                            </validation-provider>
                            <validation-provider
                              :name="$t('Remark')"
                              autocomplete="off"
                              v-slot="{errors}"
                            >
                            <b-form-group class="d-inline col-12 font-weight-bold text-primary-dark" for="remark" :label="$t('Remark')">
                              <b-form-textarea
                                id="remark"
                                rows="3"
                                max-rows="6"
                                v-model="formTransfer.remark"
                                :placeholder="$t('Remark')">
                              </b-form-textarea>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                            </validation-provider>
                            <div class="w-100 justify-content-center d-flex">
                              <b-button pill class="mt-2 px-5 btn-light font-weight-bold d-inline" @click="$bvModal.hide('modal-add-transfer'), resetFormTransfer()">
                                {{ $t('Cancel') }}
                              </b-button>
                              <b-button pill type="submit" :disabled="isLoadingTransfer" class="d-inline ml-3 mt-2 px-5 font-weight-bold btn-primary-dark">
                                <b-spinner small class="mr-3" v-if="isLoadingTransfer"  label="Loading..."/>
                                {{ $t('Save') }}
                              </b-button>
                            </div>
                          </b-form>
                        </ValidationObserver>
                      </div>
                    </div>
                  </b-card>
                </div>
              </b-row>
            </div>
            <div class="col-12 col-lg-4 mt-4">
              <b-row class="p-2">
                <b-col lg='12' class="orders-summary">
                  <b-card :title="$t('Order Summary')" :sub-title="$t('Calculate all purchases')" class="">
                    <div class="flex-justify-content-space-between mt-3">
                      <b-card-text class="text-title-bold">{{ $t('Sub total') }}</b-card-text>
                      <div class="">
                        <b-card-text class="text-amount">{{ getAmountByCurrency(invoice, 'sub_total', invoice.paid_currency) | numeral('0,0.00') }} <span class="text-uppercase">{{ invoice.paid_currency }}</span></b-card-text>
                      </div>
                    </div>
                    <div class="flex-justify-content-space-between">
                      <b-card-text class="text-title-bold">{{ $t('Discount') }}</b-card-text>
                      <div class="">
                        <b-card-text class="text-amount">{{ getAmountByCurrency(invoice, 'discount_amount', invoice.paid_currency) | numeral('0,0.00') }} <span class="text-uppercase">{{ invoice.paid_currency }}</span></b-card-text>
                      </div>
                    </div>
                    <div class="flex-justify-content-space-between">
                      <b-card-text class="text-title-bold">{{ $t('Total') }}</b-card-text>
                      <div class="">
                        <b-card-text class="text-amount">{{ getAmountByCurrency(invoice, 'total', invoice.paid_currency) | numeral('0,0.00') }} <span class="text-uppercase">{{ invoice.paid_currency }}</span></b-card-text>
                      </div>
                    </div>
                    <div class="flex-justify-content-space-between">
                      <b-card-text class="text-title-bold">{{ $t('Include VAT/GST') }} ({{ invoice.vat_percent | numeral('0,0.00') }}%)</b-card-text>
                      <div class="">
                        <b-card-text class="text-amount">{{ getAmountByCurrency(invoice, 'vat', invoice.paid_currency) | numeral('0,0.00') }} <span class="text-uppercase">{{ invoice.paid_currency }}</span></b-card-text>
                      </div>
                    </div>
                    <b-row>
                      <b-col>
                        <hr/>
                      </b-col>
                    </b-row>
                    <div class="flex-justify-content-space-between mt-3 mb-2">
                      <h5 class="text-title-bold">{{ $t('Grand total') }}</h5>
                      <div class="columns">
                        <h5 class="text-danger text-title-bold">{{ getAmountByCurrency(invoice, 'grand_total', invoice.paid_currency) | numeral('0,0.00') }} <span class="text-uppercase">{{ invoice.paid_currency }}</span></h5>
                      </div>
                    </div>
                  </b-card>
                  <b-row class="mt-3">
                    <b-col md='12' lg="12" class="mb-3">
                      <b-form-checkbox
                        id="accept"
                        v-model="acceptSelected"
                        name="accept"
                        value="accepted"
                        unchecked-value="not_accepted"
                      >
                        {{ $t('I accept the terms and use & Condition/Privacy of WYA Yoga') }} (<span><router-link :to="{ name: 'termsAndConditions' }" exact class="font-weight-bold text-primary-dark ml-2 mr-2" target="_blank">{{ $t('Terms and Conditions') }}</router-link></span> | <span> <router-link :to="{ name: 'privacyPolicy' }" exact class="font-weight-bold text-primary-dark ml-2 mr-2" target="_blank">{{ $t('Privacy Policy') }}</router-link></span>)
                      </b-form-checkbox>
                      <!-- <div>State: <strong>{{ acceptSelected }}</strong></div> -->
                    </b-col>
                    <b-col class="d-none">
                      <form id="checkoutForm">
                        <input type="hidden" name="omiseToken">
                        <input type="hidden" name="omiseSource">
                        <b-button id="checkoutButton" :disabled="isDisablePayment" block class="btn-danger-light btn-danger-light-shadow" size="lg" variant="danger">
                        {{ $t('Pay') }}
                      </b-button>
                      </form>
                    </b-col>
                    <b-col :disabled="isDisablePayment" v-bind:class="{ 'd-none': paymentSelected != 'paypal' }">
                      <b-button id="paypal-button" block class="bg-white border-0" size="sm"></b-button>
                    </b-col>
                    <!-- <b-col :disabled="isDisablePayment" v-if="paymentSelected == 'other'">
                      <b-button block class="border-0 btn-primary font-weight-bold" @click="$bvModal.show('modal-add-transfer')">{{ $t('Transfer') }}</b-button>
                    </b-col> -->
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button variant="outline-secondary" class="mt-3" exact :to="{name: 'billingCheckout', params: { invoiceId: invoice.id }}">
                {{ $t('Back') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-modal
        id="modal-loading"
        size="sm"
        centered
        hide-header
        hide-footer
        body-class="bg-tranparent"
        >
        <div class="text-center">
          <p>{{ $t('In progress') }}</p>
            <b-spinner class="text-primary-dark" label="Loading"></b-spinner>
        </div>
        </b-modal>
    </b-container>
  </div>
</template>
<script type="text/javascript" src="https://cdn.omise.co/omise.js" async defer></script>
<script>
import Api from '../../../api/api'
import StepFormRegister from '../../../components/form/StepFormRegister'
import 'vue-select/dist/vue-select.css'
import '@livelybone/vue-datepicker/lib/css/index.css'
import axios from 'axios'
const today = new Date()
export default {
  name: 'PaymentDetail',
  title: ' | Payment Detail',
  components: {
    StepFormRegister
  },
  data () {
    return {
      currentStep: 1,
      titleListSteps: [this.$t('Bill Info'), this.$t('Payment'), this.$t('Finished')],
      loading: true,
      orderFields: [
        { key: 'item_code', label: this.$t('Item code') },
        { key: 'name', label: this.$t('Product') },
        { key: 'quantity', label: this.$t('Quantity') },
        { key: 'price', label: this.$t('Price') },
        { key: 'total', label: this.$t('Total') }
      ],
      orders: [],
      paymentSelected: 'paypal',
      acceptSelected: null,
      isLoading: true,
      invoice: {},
      invoiceId: null,
      omiseToken: '',
      paymentForm: {
        invoice_id: null,
        payload: null,
        payment_provider: 1, // default omise
        payment_method: 1, // default credit card [1: credit card, 2: transfer, 3:cash, 4:counter service]
        payment_type: 1 // default full payment [1:full, 2: installment]
      },
      isMakePayment: false,
      banks:[
        {
          name: 'Bangkok Bank',
          account_name: 'Mr. DHARMENDRA SINGH',
          account_number: '511-7-152560',
          branch_number: '',
          branch: ' Central Plaza Chiang Rai ,City Chiang Rai, THAILAND',
          swiftCode: 'BKKBTHBK',
          iFSCCode : '',
          accountType: '',
          remark: ''
        },
        {
          name: 'PUNJAB NATIONAL BANK',
          account_name: 'WORLD YOGA ALLIANCE',
          account_number: '6584002100000905',
          branch_number: '',
          branch: 'SWAROOP NAGAR DELHI-110042',
          swiftCode: 'PUNBINBBISB',
          iFSCCode : 'PUNB0658400',
          accountType: 'Current Account',
          remark: 'India & Nepal Resident ONLY'
        },
        {
          name: 'Western Union or MoneyGrams',
          account_name: 'DHARMENDRA SINGH',
          account_number: '',
          branch_number: '',
          branch: 'H.N. 376 Village No. 7 Phahonyothin Rd, Tambon Nang Lae, Amphoe Mueang, CITY: CHIANG RAI, Country: Thailand, PIN: 57100, PHONE: +66946364155',
          swiftCode: '',
          iFSCCode : '',
          accountType: '',
          remark: ''
        },
        {
          name: 'Transfer Wise',
          account_name: 'Dharmendra Singh',
          account_number: '',
          branch_number: 'BE50 9671 9436 4118',
          branch: '',
          swiftCode: '',
          iFSCCode : '',
          accountType: '',
          remark: ''
        }
      ],
       bankOptions: [
        { item: 0, name: 'Bangkok Bank, ' + this.$t('Account name') + ': Mr. DHARMENDRA SINGH, '+ this.$t('Account number') + ': 511-7-152560' },
        { item: 1, name: 'PUNJAB NATIONAL BANK, ' + this.$t('Account name') + ': WORLD YOGA ALLIANCE, '+ this.$t('Account number') + ': 6584002100000905' },
        { item: 2, name: 'Western Union or MoneyGrams, ' + this.$t('Account name') + ': DHARMENDRA SINGH, '+ this.$t('Branch') + ': H.N. 376 Village No. 7 Phahonyothin Rd, Tambon Nang Lae, Amphoe Mueang, CITY: CHIANG RAI, Country: Thailand, PIN: 57100, PHONE: +66946364155' },
        { item: 3, name: 'Transfer Wise, ' + this.$t('Account name') + ': Dharmendra Singh, '+ this.$t('Account number') + ': 262-5-30053-4' }
      ],
      imageTransfer: null,
      formTransfer: {
        user: null,
        payment_provider: 3, // Other
        payment_ref: null,
        invoice: null,
        payment_method: 2, // Transfer
        payment_type: 1,
        amount: null,
        document_transfer: null,
        bank: null,
        bank_name: null,
        account_name: null,
        account_number: null,
        bank_branch: null,
        transfer_date: new Date(),
        transfer_time: today.getHours() + ":" + today.getMinutes(),
        status: 1, // Waiting for approve
        remark: null,
      },
      isLoadingTransfer: false,
      timeStr: ['hour', 'min', 'sec']
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      this.onToggleCollapeId(collapseId, isJustShown)
    })
    this.invoiceId = this.$route.params.invoiceId
    this.paymentForm.invoice_id = this.invoiceId
    await this.invoiceDetail(this.invoiceId)
    // await this.doBankOptions()
    OmiseCard.configure({
      publicKey: process.env.VUE_APP_OMISE_PUBLIC_KEY
    })
    var button = document.querySelector('#checkoutButton')
    var form = document.querySelector('#checkoutForm')
    button.addEventListener('click', (event) => {
      event.preventDefault()
      OmiseCard.open({
        amount: this.getAmountByCurrency(this.invoice, 'grand_total', this.invoice.paid_currency).replace('.', ''),
        currency: this.invoice.paid_currency,
        locale: (this.currentLanguage === 'th' ? this.currentLanguage : 'en'),
        frameLabel: 'World Yoga Alliance',
        image: window.location.origin + '/' + 'img/icons/android-chrome-192x192.png',
        defaultPaymentMethod: 'credit_card',
        otherPaymentMethods: 'installment',
        onCreateTokenSuccess: (nonce) => {
          if (nonce.startsWith('tokn_')) {
            form.omiseToken.value = nonce
            this.omiseToken = nonce
            this.paymentForm.payload = nonce
          } else {
            form.omiseSource.value = nonce
            this.omiseToken = nonce
            this.paymentForm.payload = nonce
          }
          this.makePayment()
        }
      })
    })
  },
  computed: {
    isDisablePayment () {
      const _return = this.acceptSelected === 'accepted' && this.paymentSelected != null ? null : true
      return _return
    },
    currentLanguage () {
      return this.$store.getters.currentLanguage
    },
    userProfile () {
      return this.$store.getters.userProfile
    }
  },
  methods: {
    doBankOptions () {
        let list =[]
        let _this = this
        //console.log ('this.banks.length', this.banks.length)
        if(this.banks.length > 0) {
          this.banks.forEach(function(bank, i) {
            list.push({item: i, name: `${bank.name}, ${_this.$t('Account name')}: ${bank.account_name} ${_this.$t('Account number')}: ${bank.account_number}` })
          })
        } else {
          list.push({item: 0, name : ''})
        }
        this.bankOptions = list
    },
    onContextDate () {
      this.formTransfer.transfer_date = this.$options.filters.dateFormat(new Date(this.formTransfer.transfer_date), 'YYYY-MM-DD')
      document.getElementById('input-date__value_').innerHTML = this.formTransfer.transfer_date
    },
    onToggleCollapeId (collapeId, isJustShown) {
      const chevronDown = document.querySelector('#' + collapeId + '-toggle' + ' span.chevron-down')
      const chevronUp = document.querySelector('#' + collapeId + '-toggle' + ' span.chevron-up')
      if (chevronDown) {
        if (isJustShown === true) {
          // Open
          chevronDown.classList.add('d-none')
        } else {
          // Closed
          chevronDown.classList.remove('d-none')
        }
      }
      if (chevronUp) {
        if (isJustShown === true) {
          // Open
          chevronUp.classList.remove('d-none')
        } else {
          // Closed
          chevronUp.classList.add('d-none')
        }
      }
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    invoiceDetail (invoiceId) {
      this.isLoading = true
      Api.invoiceDetail({ invoiceId: invoiceId }).then( async (response) => {
        this.invoice = response.data
        this.orderDetail(this.invoice.order)
        this.formTransfer.user = this.userProfile.id
        this.formTransfer.invoice = this.invoice.id
        this.formTransfer.amount = this.invoice['grand_total_'+this.invoice.paid_currency]
        this.isLoading = false
      })
        .catch(() => {
          this.messageError = ''
          this.isLoading = false
        })
    },
    orderDetail (orderId) {
      this.isLoading = true
      Api.orderDetail({ orderId: orderId }).then((response) => {
        this.orders = response.data.order_item_set
        this.configPaypal()
      })
        .catch(() => {
          this.messageError = ''
          this.isLoading = false
        })
    },
    makePayment () {
      if (!this.isMakePayment) {
        this.isMakePayment = true
        this.$bvModal.show('modal-loading')
        Api.makePayment(this.paymentForm).then((response) => {
          this.$bvModal.hide('modal-loading')
          this.$router.push({ name: 'billingCheckoutComplete', params: { invoiceId: this.invoiceId } })
        })
          .catch(() => {
            this.$bvModal.hide('modal-loading')
            this.messageError = ''
            this.isLoading = false
            this.isMakePayment = false
          })
      }
    },
    getAmountByCurrency (item, field, currency) {
      const fieldName = field + '_' + currency
      return item[fieldName]
    },
    configPaypal () {
      var itemLists = []
      var invoiceRef = this.invoice.ref_invoice
      var invoice = this.invoice
      this.orders.forEach( item => {
        var orderItem = {
          name: item.name,
          quantity: item.quantity,
          price: this.getAmountByCurrency(item, 'price', this.invoice.paid_currency),
          tax: '0',
          sku: item.item_code,
          currency: this.invoice.paid_currency.toUpperCase()
        }
        itemLists.push(orderItem)
      })
      var paymentAmount = {
        total: this.getAmountByCurrency(this.invoice, 'grand_total', this.invoice.paid_currency),
        currency: this.invoice.paid_currency.toUpperCase(),
        details: {
          subtotal: this.getAmountByCurrency(this.invoice, 'grand_total', this.invoice.paid_currency),
          shipping: '0',
          handling_fee: '0',
          shipping_discount: '0'
        }
      }
      paypal.Button.render({
        env: process.env.VUE_APP_PAYPAL_MODE,
        client: {
          sandbox: process.env.VUE_APP_PAYPAL_CLIENT_ID_SANDBOX,
          production: process.env.VUE_APP_PAYPAL_CLIENT_ID_PRODUCTION
        },
        locale: 'en_US',
        style: {
          size: 'medium',
          color: 'gold',
          shape: 'pill',
          label: '',
          tagline: 'true'
        },
        commit: true, // Optional: show a 'Pay Now' button in the checkout flow
        payment: function(data, actions) {
          return actions.payment.create({
            transactions: [{
              amount: paymentAmount,
              invoice_number: invoiceRef,
              item_list: {
                items: itemLists
              }
            }]
          })
        },
        onAuthorize: function(data, actions) {
          return actions.payment.execute().then(function()
          {
            var paymentForm = {
              invoice_id: invoice.id,
              payload: JSON.stringify(data),
              payment_provider: 2, // paypal
              payment_method: 4, // paypal
              payment_type: 1 // full payment
            }
            Api.makePayment(paymentForm).then((response) => {
                window.location = "/billing/checkout/complete/" + invoice.id;
              })
              .catch(() => {
                window.alert('Unsuccessfully payment')
              })
          })
          .catch(() => {
            window.alert('Unsuccessfully payment')
          })
        }
      }, '#paypal-button')
    },
    onSubmitTransfer () {
      this.$refs.formTransfer.validate().then(result => {
        if (result) {
          const bank = this.banks[this.formTransfer.bank]
          this.formTransfer.payment_ref = 'TRF'+ Date.now().toString()
          this.formTransfer.request_text = JSON.stringify(bank)
          this.formTransfer.bank_name = bank.bank_name
          this.formTransfer.account_name = bank.account_name
          this.formTransfer.account_number = bank.account_number
          this.formTransfer.bank_branch = bank.bank_branch
          this.formTransfer.transfer_date = this.$options.filters.dateFormat(new Date(this.formTransfer.transfer_date), 'YYYY-MM-DD')
          this.addPaymentTransfer()
        }
      })
    },
    addPaymentTransfer () {
      this.isLoadingTransfer = true
      Api.addPaymentTransaction(this.formTransfer).then( async (response) => {
        this.isLoadingTransfer = false
        this.$router.push({ name: 'billingCheckoutComplete', params: { invoiceId: this.formTransfer.invoice }})
      })
      .catch(() => {
        this.$notify({
            group: 'error',
            title: this.$t('Upload document transfer'),
            text: this.$t('Upload document transfers amount not successfully')
          })
        this.isLoadingTransfer = false
      })
    },
    resetFormTransfer () {
      console.log('reset')
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style scoped>
  .modal .animation-content .modal-card {
    overflow: visible !important;
  }
  .modal-body {
    overflow: visible !important;
  }
</style>
